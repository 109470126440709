import React from "react";
import Layout from "../../components/Layout";
import {
  ImageInterface,
  LandingSectionsInterface,
  LocalizedContextInterface,
  SanityGraphQLConnection,
  Seo,
  SocialMediaChannel
} from "../../types/SanityTypes";
import { graphql, PageProps } from "gatsby";
import SEO from "../../components/Seo";
import { useSiteMetadata } from "../../hooks/useSiteMetadata";
import StructuredData from "../../components/StructuredData";
import BlockRenderer from "../../components/BlockRenderer";
import { LocalisedPageContext } from "../../types/PageTypes";
import TeaserSeparator from "../../components/TeaserSeparator";

type HomepageProps = {
  home: {
    seo: Seo;
    slug: {
      current: string;
    };
    name: string;
    heading: string;
    _rawLogo: ImageInterface;
    logo: ImageInterface;
    landingSections: LandingSectionsInterface[];
  };
  socialMedia: SanityGraphQLConnection<SocialMediaChannel>;
} & ContactInterface &
  LocalizedContextInterface;

export interface ContactInterface {
  contact: {
    address: string;
    city: string;
    country: string;
    postcode: string;
    telephone: string;
    contactNo: {
      contactType: string;
      telephoneNo: string;
    }[];
  };
}

type HomePageContext = LocalisedPageContext;

export const query = graphql`
  query ($_id: String, $language: String) {
    socialMedia: allSanitySocialMediaChannel {
      edges {
        node {
          url
        }
      }
    }
    contact: sanityContactInformation(i18n_lang: { eq: $language }) {
      address
      city
      country
      postcode
      telephone
      contactNo {
        contactType
        telephoneNo
      }
    }
    home: sanityHomePage(_id: { eq: $_id }) {
      ...HomePageFieldsFull
    }
    ...LocalizedContext
  }
`;

const HomePage = (props: PageProps<HomepageProps, HomePageContext>) => {
  const homePage = props.data.home;
  const logo = props.data.home?._rawLogo || props.data.home?.logo;
  const { siteUrl, htmlLang, siteName } = useSiteMetadata(props.pageContext.language);
  const structuredData = { logo, ...props.data.socialMedia, ...props.data.contact, siteName };

  return (
    <Layout
      localizedContext={{ ...props.pageContext, ...props.data }}
      pageName={homePage?.seo.metaTitle}
      pageType={"home"}
      contentType={"Homepage"}
      analyticsTagMeta={{ promoId: "1" }}
    >
      <StructuredData
        type={"Organisation"}
        name={siteName}
        description={homePage?.seo.metaDescription}
        slug={siteUrl}
        image={{
          url: logo?.asset.url as string
        }}
        data={structuredData}
        language={htmlLang}
      />
      <SEO
        title={homePage?.seo.metaTitle}
        description={homePage?.seo.metaDescription}
        imageUrl={logo?.asset.url}
        imageAlt={logo?.alt}
      />

      {homePage?.landingSections && (
        <>
          {homePage.landingSections.map((section, index) => (
            <BlockRenderer key={"landingSection" + index} index={index} section={section} />
          ))}
        </>
      )}
    </Layout>
  );
};

export default HomePage;
